import { Link } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Othersevice({ otherData, head }) {
    return (
        <section>
            <div className="other-service">

                <Container>
                    <div className="heading">
                        <h2>
                            {head}
                        </h2>
                    </div>

                    <Row className='justify-content-center  mt-5'>
                        {
                            otherData && otherData.map((data, i) => {
                                let linkurl = data.redirecturl;
                                return (
                                    <div className=" w-auto mt-4 m-xl-3" key={i}>

                                        <Link to={linkurl} className="anchor_link">
                                            <div className="other-service-box " data-aos="zoom-out" data-aos-delay="500" >
                                                <img src={data.img} alt={data.title} width="155" height="155" className='wh-auto'/>
                                                <h2>{data.title}</h2>
                                            </div>
                                        </Link>

                                    </div>
                                )
                            })
                        }
                    </Row>
                </Container>


            </div>
        </section>
    )
}

export default Othersevice
