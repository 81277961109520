import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { Link } from "gatsby";


function Faq(props) {
    return (

        <section>
            <div className="Faq small-Faq">
                <Container>
                    <div className="heading">
                        <h2>{props.title}</h2>
                    </div>
                    <div className='title mb-4'>
                        <h3>Get all your questions answered before hiring the right resource</h3>
                    </div>

                    <Row className="mt-5 justify-content-center">
                        <Col lg={6}>
                            <Accordion defaultActiveKey="0">
                                {
                                    props.faqData && props.faqData.map((faq, i) => {
                                        return (
                                            <Accordion.Item eventKey={i} key={i} data-aos="fade-right">
                                                <Accordion.Header>
                                                    <h5>{faq.que}</h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>{faq.answer}</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                            <Link to="/faqs" className='text-decoration-none'>
                                <button className='hero-btn mt-2 mt-lg-4 '>
                                    {props.buttnText}
                                </button>
                            </Link>

                        </Col>

                        <Col lg={6}>
                            <div className="Faq-img-box text-center  hide-animation-768 mt-4" data-aos="fade-left">
                                <img src="../assets/img/mobileapp/small_faq/main.svg" alt="main" className='img-fluid wh-auto' width="743" height="581" />
                                <img src="../assets/img/mobileapp/small_faq/faq1.svg" alt="Faq1" className='img-fluid Faq-1' />
                                <img src="../assets/img/mobileapp/small_faq/faq2.svg" alt="Faq2" className='img-fluid Faq-2' />
                                {/* <img src="../assets/img/mobileapp/small_faq/Faq3.png" alt="Faq3" className='img-fluid Faq-3' /> */}
                                <img src="../assets/img/mobileapp/small_faq/faq3.svg" alt="Faq4" className='img-fluid Faq-4' />
                                <img src="../assets/img/mobileapp/small_faq/faq5.svg" alt="Faq5" className='img-fluid Faq-5' />
                            </div>
                            <div className="main-img show-main-img-768 ">
                                <img src="../assets/img/mobileapp/small_faq/faq_mobile.svg" className="img-fluid main-img wh-auto" alt="main_img" width="662" height="518" />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default Faq