
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from "gatsby";
import Slider from 'react-slick';
import allData from '../../allData';

const Hireservice = ({ serviceData, h1 }) => {


    return (
        <>

            <section className='service mobile-app-service hireservice  '>
                <div className="heading" data-aos="zoom-in">
                    <h2 > {h1} </h2>
                </div>
                <Container >
                    <Row className='web-review  justify-content-center hide-768 mt-5 Hirecard '>
                        {
                            serviceData.map((e, value) => {
                                return (
                                    <div className="w-auto margin-btm " key={value} >
                                        <div className='service-box '>
                                            <div className={e.class} data-aos="zoom-in" data-aos-delay="500" >
                                                <div className='d-flex justify-content-center'>
                                                    <div className=' Hirecard-img'>
                                                        <img src={e.img} alt={e.Head} className='wh-auto' width="105" height="105"/>
                                                    </div>
                                                </div>
                                                <div className='service-text mt-5'>
                                                    <h4> {e.Head} </h4>
                                                    <p> {e.conent} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        }


                    </Row>
                    <div className="servicecard">
                        <Slider {...allData.settings} className="show-768">
                            {
                                serviceData.map((e, value) => {
                                    return (
                                        <div className='px-1 h-100' key={value}>
                                            <div className={e.class} >
                                                <div className='d-flex justify-content-center'>
                                                    <div className={e.subclass} >
                                                        <img src={e.img} alt={e.Head} className='wh-auto' width="60" height="60" />
                                                    </div>
                                                </div>
                                                <div className='service-text mt-5'>
                                                    <h4> {e.Head} </h4>
                                                    <p> {e.conent} </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    <Link to="/quotation"
                        className=' view-all hero-btn-bg mt-2 '>
                        Hire Now
                    </Link>
                </Container>

            </section>
        </>
    )
}

export default Hireservice
