import { data } from 'jquery'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import allData from '../../allData'


function Benefit(props) {
    return (
        <section>
            <div className="box-one">
                <div className="heading" data-aos="zoom-in">
                    <h2  > {props.h1} </h2>
                </div>
                <div className='px-3'>
                    <Row className='row-wrap justify-content-center mt-5'>
                        {
                            allData.benefitdata.map((e, value) => {
                                return (
                                    <div key={value} className="after-line p-0" >
                                        <div className="benefit-box ">
                                            <img src={e.img} alt={e.Head}  className='wh-auto' width="100" height="100"/>
                                            <div className="benefit-content">
                                                <h4> {e.Head} </h4>
                                                <p> {e.content} </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </Row>
                </div>
            </div>
        </section>
    )
}

export default Benefit