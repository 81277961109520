
import React, { useState, useEffect } from "react";
import PortfolioCom from "../components/Home/PortfolioCom";
import aallData from "../api.js";
import WhyVasundhara from "../components/Home/WhyVasundhara";
import Testimonials from "../components/Home/Testimonials";
import Othersevice from "../components/Mobileapp/Othersevice";
import Blog from "../components/Home/Blog";
import Faq from "../components/Mobileapp/Faq";
import allData from '../allData.js'
import Benefit from "../components/HireoffshorComp/Benefit";
import Layout from "../components/Layout";
import Hero from "../components/Mobileapp/hero";
import Seo from "../components/Seo";
import Hireservice from '../components/HireoffshorComp/Hireservice';
import Offshordeveloper from '../components/HireoffshorComp/Offshordeveloper';
import IndustriesCom from '../components/Home/IndustriesCom';
import UpworkCard from '../components/Mobileapp/Upworkcard';
import allApiData from "../api";
import SeoheadData from "../siteScriptData";


const HirecardDataone = allData.HirecardDataone;
const HirecardDatatwo = allData.HirecardDatatwo;
const hireFaqData = allData.hireoffsoreFaqData;
const HirecardDatathree = allData.HirecardDatathree;
const whyhireData = allData.whyhiredata;
const OffshoreDevelopersdata = allData.OffshoreDevelopersdata;
const OtherGameData = allData.otherServiceData('hire');

function Hireoffer() {
    const [allData, setallData] = useState({});
    useEffect(() => {
        aallData.getHomeApi().then((response) => {
            setallData(response)
        })
    }, [])
    const mobiletabDAta = ['Frontend', 'Backend', 'Testing'];
    // const mobiletabDAta = ['Design', 'Frontend', 'Backend', 'Testing'];

    useEffect(() => {
        allApiData.aosinit()
    }, []);
    return (
        <Layout>
            <Seo SeoData={SeoheadData.hieoffshorePageSeoData}></Seo>
            <main>
                <div className="hire-offer">
                    <Hero title="Hire Offshore Developers"
                        cta="Get a Quote"
                        def="Get access to a wider talent pool and efficient professionals to build your next app.">
                        <div className="hero-img hide-animation-768 m-0 ms-auto">
                            <img src="../assets/img/hireoffshor/hero_img/hire-offshore-developers.svg" className="wh-auto z-index img-fluid main-img-only" alt="hire-offshore-developers"
                             width="564" height="595" />
                            <img src="../assets/img/hireoffshor/hero_img/1.svg" className=" img-fluid img-1 " alt="Object-1" />
                            <img src="../assets/img/hireoffshor/hero_img/2.svg" className=" img-fluid img-2 " alt="Object-2" />
                        </div>
                        <div className="show-main-img-768">
                            <img src="../assets/img/hireoffshor/hero_img/hire-offshore-developers-mobile.svg" className=" img-fluid" alt="hire-offshore-developers-mobile"
                            width="564" height="600" />
                        </div>
                    </Hero>
                </div>

                <UpworkCard />
                <div className='bg-gray all-hire '>
                    <Hireservice h1="Hire App Developers" serviceData={HirecardDataone} />
                </div>
                <div className='bg-gray white-bg all-hire'>
                    <Hireservice h1="Hire Game Developers" serviceData={HirecardDatatwo} />
                </div>
                <div className='bg-gray all-hire'>
                    <Hireservice h1="Hire Web Developers" serviceData={HirecardDatathree} />
                </div>


                <div className="benefit">
                    <Benefit h1="Why Hire Offshore Development Team?" />
                </div>
                <Offshordeveloper h1="How to Hire Offshore Developers from Vasundhara?" />

                <div className="bg-blue-light py-lg-5">
                    <PortfolioCom heading="Technologies we work with" activeTab="Frontend" isTabOn={true} tabData={mobiletabDAta} portfolios={allData.portfolios} isImageComp={false} isLimitPortF={10} />
                </div>

                <div className="spacer-head2">
                    <PortfolioCom heading="Portfolio For Hire offshore developers "
                        portfolios={allData.portfolios} isLimitPortF={3}
                        isTabOn={false}
                        isImageComp={true} />
                </div>
                <div className="hiredevelopr">
                    <IndustriesCom h1="Why Hire Dedicated Offshore Developers From Vasundhara" industriesData={OffshoreDevelopersdata} buttonOn={false} />
                </div>
                <WhyVasundhara datas={whyhireData} />
                <Testimonials testimonialsdata={allData.testimonials} />
                <Othersevice head="Check Out Other Services We Offer" otherData={OtherGameData} />
                <div className="blog-section nft-blog">
                    <Blog heding="Latest Blogs and Insights from Vasundhara" blogdata={allData.blogs} />
                </div>
                <Faq title="FAQ's for Hire offshore developers" faqData={hireFaqData} buttnText='Check out more FAQs here' />
            </main >
        </Layout >
    )
}

export default Hireoffer
