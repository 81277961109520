import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import allApiData from '../../api';

const Blog = (props) => {
    let {
        blogdata,
        casestudy = false,
        limitBlog = 3,
        heding,
        btnnone = true,
        banner_image
    } = props;

    function capitalizeFirstLetter(string) {
        if (string) { return string.charAt(0).toUpperCase() + string.slice(1); }
    }

    return (
        <>
            {
                blogdata && blogdata.length != 0 ?
                    <section className="blog pb-100">
                        <Container className="container2">
                            <div className=" heading text-center" data-aos="zoom-in">
                                <h2>{heding}</h2>
                            </div>

                            <div className="blog-main mt-5">
                                <Row className="justify-content-center">
                                    {
                                        casestudy ?
                                            <>
                                                {
                                                    blogdata && blogdata.slice(0, limitBlog).map((_casestudy, i) => {
                                                        return (
                                                            <Col
                                                                xl={4}
                                                                lg={6}
                                                                md={6}
                                                                sm={12}
                                                                key={i}
                                                                className="mb-4"
                                                            >
                                                                <div className="blog-box" data-aos="zoom-in">
                                                                    <Link to={`/case-study/${_casestudy.tag}`}>
                                                                        <img src={_casestudy.medias[0].image_path} alt={_casestudy.title} />
                                                                    </Link>

                                                                    <div className="p-3 blog-title">
                                                                        <div className="time-box d-flex align-items-center ">
                                                                            <span>
                                                                                {
                                                                                    allApiData.dateConverter(_casestudy.created_at && _casestudy.created_at)
                                                                                }
                                                                            </span>
                                                                            <span>
                                                                                Category :{" "}
                                                                                <span>{capitalizeFirstLetter(_casestudy.category)}</span>
                                                                            </span>
                                                                        </div>
                                                                        <Link to={`/case-study/${_casestudy.tag}`}>
                                                                            <h4 className="mt-3">{_casestudy.title}</h4>
                                                                        </Link>
                                                                        <Link
                                                                            to={`/case-study/${_casestudy.tag}`}
                                                                            state={{ blogid: _casestudy.title }}
                                                                            className="read_more"
                                                                        >
                                                                            Read More
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        );
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {blogdata && blogdata.slice(0, limitBlog).map((blog, i) => {
                                                    return (
                                                        <Col
                                                            xl={4}
                                                            lg={6}
                                                            md={6}
                                                            sm={12}
                                                            key={i}
                                                            className="mb-4"
                                                        >
                                                            <div className="blog-box" data-aos="zoom-in">
                                                                <Link to={`/blogs/${blog.tag}`}>
                                                                    <img src={blog.banner_image} alt={blog.title} className='wh-auto' width='467' height='257' className='wh-auto' />
                                                                </Link>

                                                                <div className="p-3 blog-title">
                                                                    <div className="time-box d-flex align-items-center ">
                                                                        <span>
                                                                            {
                                                                                allApiData.dateConverter(blog.date && blog.date)
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            Category :{" "}
                                                                            <span>{capitalizeFirstLetter(blog.category.name)}</span>
                                                                        </span>
                                                                    </div>
                                                                    <Link to={`/blogs/${blog.tag}`}>
                                                                        <h4 className="mt-3">{blog.title}</h4>
                                                                    </Link>
                                                                    <Link
                                                                        to={`/blogs/${blog.tag}`}
                                                                        state={{ blogid: blog.title }}
                                                                        className="read_more"
                                                                    >
                                                                        Read More
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                            </>

                                    }
                                </Row>
                                {
                                    btnnone
                                        ?
                                        <div className="d-flex justify-content-center mt-4">
                                            {/* <Link to="/" className='view-all'>Read all blogs</Link> */}
                                            <Link to="/blogs" className="view-all">
                                                Read all blogs
                                            </Link>
                                        </div>
                                        :
                                        ""
                                }

                            </div>
                        </Container>
                    </section>
                    : ''
            }
        </>
    );
};

export default Blog;
