
import { Link } from 'gatsby';
import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick';
import allData from '../../allData';

let url = 'https://outsourcingvasundhara.sfo3.cdn.digitaloceanspaces.com'
const Testimonials = ({ testimonialsdata }) => {

    // useEffect(() => {
    //     (function () {
    //         allApiData.sameheighttestimonils(false, "testmonial_text")
    //     })();

    //     window.onresize = function () {
    //         allApiData.sameheighttestimonils(true, "testmonial_text")
    //     }


    // }, [testimonialsdata])

    return (
        <>
            <section className='testimonials'>
                <Container>
                    <div className='heading text-center pb-lg-3' data-aos="zoom-in" >
                        <h2>Our customers love us. Here’s why.</h2>
                    </div>
                    <div className='my-5' data-aos="zoom-in">

                        <Slider {...allData.settings} className="w-100 testimonials-slider ">
                            {
                                testimonialsdata && testimonialsdata.map((testmonial, i) => (
                                    <div key={i} className='px-xl-3 px-lg-2 px-sm-2 px-1 h-100 w-100' >
                                        <div className='testimonial-box  d-flex flex-column h-100'>
                                            <div className="client-box">
                                                <div className='client d-flex align-items-center'>
                                                    <img src={testmonial.icon_url ? testmonial.icon_url : '../assets/img/testimonials/1.png'} alt={testmonial.name} width='120' height='120' className='wh-auto' />

                                                    <div className='client-name'>
                                                        <h4>{testmonial.name}</h4>
                                                        {/* <span>{testmonial.industry}</span> */}
                                                    </div>
                                                </div>
                                                <div className='client-def'>
                                                    <p className='testmonial_text'>{testmonial.description}</p>
                                                </div>
                                            </div>

                                            <div>
                                                <img src={`${url}/${testmonial.platform_logo}`} alt={testmonial.name} className='mt-4 wh-auto' width='150' height='41' />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <Link to="/client-testimonials" className='view-all'>Read all testimonials</Link>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Testimonials
