import { Link } from 'gatsby'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

const WhyVasundhara = ({ datas }) => {
  return (
    <>
      <section className='why-vasundhara service'>
        <Container>
          <div className='d-flex justify-content-center'>
            <Button className='why-vasu-title'>
              <span>Why Vasundhara</span>
            </Button>
          </div>
          <div className='why-vasu-arrow d-flex justify-content-center gap-2 my-2'>
            <img src="../assets/img/why-vasundharaimg/6.png" className='left-img' alt="why-vasundharaimg-six" />
            <img src="../assets/img/why-vasundharaimg/5.png" className='center-img' alt="why-vasundharaimg-five" />
            <img src="../assets/img/why-vasundharaimg/4.png" className='right-img' alt="why-vasundharaimg-four" />
          </div>
          <div className='why-vasu-content text-center'>
            <Row className='justify-content-center'>
              {
                datas && datas.map((da, i) => {
                  var numclass = 'right';
                  if (i === 0) { numclass = 'right' }
                  else if (i === 1) { numclass = 'up' }
                  else if (i === 2) { numclass = 'left' };

                  return (
                    <Col xl={4} lg={4} md={6} sm={10} xs={12} className="mt-44 mt-lg-0 padding-left-right" key={i}>
                      <div className={`service-tech-box whyvasundhara-box ${da.mainclass}`} data-aos={`zoom-out-${numclass}`}>
                        <div className='d-flex justify-content-center'>
                          <div className={`tech-icon ${da.subclass}`}>
                            <img src={da.imgurl} alt={da.heading} width='90' height='90' className='wh-auto' />
                          </div>
                        </div>
                        <div className='service-text mt-5'>
                          <h4>{da.heading}</h4>
                          <p className='mt-3 sm-mt-4 '>{da.des}</p>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>

            <Link to="/why-vasundhara" className='view-all btn btn-primary mt-5'>
              More on Why Vasundhara?
            </Link>
          </div>
        </Container>
      </section>
    </>
  )
}

export default WhyVasundhara

