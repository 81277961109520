import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from "react-slick";
import allApidata from '../../api.js';

function UpworkCard() {

    const [alldata, setAlldata] = useState([]);
    const { year_of_experience, expert_team, global_client, projects_accomplished } = alldata;

    useEffect(() => {
        allApidata.getHomeApi().then((data) => {
            setAlldata(data.footer_settings)
        })
    }, [])

    var settings = {
        dots: false,
        nav: true,
        infinite: true,
        speed: 3000,
        autoplaySpeed: 5000,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (

        <section className='main-upwork'>
            <div className='upwork-box' >

                <Container className='hide-box-768'>
                    <Row className='align-items-center justify-content-center'>
                        <Col xl={3} md={4} sm={6} className='paddingZero'>
                            <div className="img-box ps-lg-5 justify-content-start" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom">
                                <img src="../assets/img/mobileapp/upworkslider/eight-years-in-industry.svg" className="img-fluid wh-auto" alt="eight-years-in-indusry" width='82' height='82' />
                                <div className='box-content' >
                                    <p >{year_of_experience}+ Years in  </p>
                                    <p >  Industry</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl={3} md={4} sm={6} className='paddingZero'>
                            <div className="img-box" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom">
                                <img src="../assets/img/mobileapp/upworkslider/two-x-growth.svg" className="img-fluid wh-auto" alt="two-x-growth" width='84' height='84' />
                                <div className='box-content' >
                                    <p >2x Growth Rate </p>
                                </div>
                            </div>
                        </Col>
                        <Col xl={3} md={4} sm={6} className='paddingZero'>
                            <div className="img-box" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom">
                                <img src="../assets/img/mobileapp/upworkslider/expert-profesionals.svg" className="img-fluid ex-profimg wh-auto" alt="expert-profesionals" width='91' height='66' />
                                <div className='box-content' >
                                    <p >{expert_team}+ Expert  </p>
                                    <p > Professionals</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl={3} md={4} sm={6} className='paddingZero'>
                            <div className="img-box border-0" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom">
                                <img src="../assets/img/mobileapp/upworkslider/projects-delivered.svg" className="img-fluid wh-auto" alt="projects-delivered" width='80' height='80' />
                                <div className='box-content' >
                                    <p >{projects_accomplished}+ Projects  </p>
                                    <p>Delivered</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>




                <div className="slider-box">
                    <Slider {...settings}>


                        <div className='img-slider-box'>
                            <div className="img-box">
                                <img src="../assets/img/mobileapp/upworkslider/eight-years-in-industry.svg" className="img-fluid wh-auto" alt="eight-years-in-indusry" width='82' height='82' />
                                <div className='box-content' >
                                    <h5>{year_of_experience}+ Years in Industry </h5>
                                </div>
                            </div>
                        </div>

                        <div className='img-slider-box'>
                            <div className="img-box">
                                <img src="../assets/img/mobileapp/upworkslider/two-x-growth.svg" className="img-fluid wh-auto" alt="two-x-growth" width='84' height='84' />
                                <div className='box-content' >
                                    <h5>2x Growth Rate </h5>
                                </div>
                            </div>
                        </div >

                        <div className='img-slider-box'>
                            <div className="img-box">
                                <img src="../assets/img/mobileapp/upworkslider/expert-profesionals.svg" className="img-fluid ex-profimg wh-auto" alt="expert-profesionals" width='91' height='66' />
                                <div className='box-content' >
                                    <h5>{expert_team}+ Expert Professionals </h5>
                                </div>
                            </div>
                        </div>

                        <div className='img-slider-box'>
                            <div className="img-box">
                                <img src="../assets/img/mobileapp/upworkslider/projects-delivered.svg" className="img-fluid wh-auto" alt="projects-delivered" width='80' height='80' />
                                <div className='box-content' >
                                    <h5>{projects_accomplished}+ Projects Delivered </h5>
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>




            </div>
        </section>



    )
}

export default UpworkCard

































