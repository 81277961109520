import React from 'react'
import { Container } from 'react-bootstrap'

function Offshordeveloper(props) {
    return (
        <div className='offshowr-developer'>
            <Container>
                <div className="heading" data-aos="zoom-in">
                    <h2 > {props.h1} </h2>
                </div>


                <div className="developer-box">
                    <div className="multi-box">
                        <div className="develop-img ">
                            <div className="blank-line-d"></div>
                            <img src="../assets/img/hireoffshor/requirements-gathering.gif" alt="requirements-gathering" className='wh-auto' width="110" height="110" />
                        </div>
                        <div className="develop-content  ">
                            <div>
                                <h4>Requirements </h4>
                                <h4>Gathering</h4>
                            </div>
                            <p>We gather requirements and understand your end goal.</p>
                        </div>
                    </div>
                    <div className="multi-box">

                        <div className="develop-content  order-1 ">
                            <div>
                                <h4>Initial </h4>
                                <h4>Screening</h4>
                            </div>
                            <p>We perform a detailed initial assessment to ensure resource availability. </p>
                        </div>
                        <div className="develop-img  order-2 ">
                            <div className="blank-line-d2"></div>
                            <img src="../assets/img/hireoffshor/intial-screening.gif" alt="intial-screening" className='wh-auto' width="110" height="110" />
                        </div>
                    </div>
                    <div className="multi-box">
                        <div className="develop-img order-2">
                            <div className="blank-line-d blank-line-d3"></div>
                            <img src="../assets/img/hireoffshor/efficrncy-check.gif" alt="efficrncy-check" className='wh-auto' width="110" height="110" />
                        </div>
                        <div className="develop-content ">
                            <div>
                                <h4>Efficiency </h4>
                                <h4>Check</h4>
                            </div>
                            <p>The offshore team goes through a comprehensive checklist for efficiency and productivity. </p>
                        </div>
                    </div>
                    <div className="multi-box">

                        <div className="develop-content">
                            <div>
                                <h4>Background </h4>
                                <h4>Verification</h4>
                            </div>
                            <p>We verify the background of each individual for best output and provide ultimate experience to customers.</p>
                        </div>

                        <div className="develop-img">
                            <div className="blank-line-d2 blank-line-d4"></div>
                            <img src="../assets/img/hireoffshor/background-verification.gif" alt="background-verification" className='wh-auto' width="110" height="110" />
                        </div>
                    </div>
                    <div className="multi-box">
                        <div className="develop-img">
                            <img src="../assets/img/hireoffshor/team-formation.gif" alt="team-formation" className='wh-auto' width="110" height="110" />
                        </div>
                        <div className="develop-content">
                            <div>
                                <h4>Offshore </h4>
                                <h4>Team Formation</h4>
                            </div>
                            <p>Once all the team members pass the assessment, we build the team and assign them to you. </p>
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default Offshordeveloper